h1, h2, h3 {
    font-family: "mplusrounded-extra-bold", "mplusrounded";
}

.text-weight-reg{
    font-family: "mplusrounded";
}

/*ajout style pour produit dici*/
#header-lp-produit-qc{
	height: 28rem;
	@include media-breakpoint-down(lg) {
	  height: 350px;
	}
}

.boxshadow {
    -webkit-box-shadow: 0px 0px 17px -1px rgba(0, 01, 10, 0.1);
    -moz-box-shadow: 0px 0px 17px -1px rgba(0, 01, 10, 0.1);
    box-shadow: 0px 0px 17px -1px rgba(0, 01, 10, 0.1);
    margin: 40px 25px;
    background-color: #fff;
    padding: 25px;
    position: relative;
  }

  .rightpush {
    position: relative;
    right: 5rem;
    margin: 0 auto;
    text-align: center;
    display: block;
  }
  
  @media screen and (max-width: 768px) {
  
    .heads2 {
        text-align: center !important;
  
    }
  
    .heads {
        margin-bottom: 0 !important;
    }
  
    .botmar {
        text-align: center !important;
  
    }
  
  }

  .prod-qc-max-lg{
    max-width: 12rem;
    margin: 0 auto;
  } 
  
  
  @media screen and (min-width: 1025px) {
    .produitici {
        max-width: 100%;
    }
  
    .rightpush {
        position: relative;
        right: 155px;
    }

    .text-rightpush{
      right: 50px;
    }
  
    
  }
  
  @media screen and (min-width: 980px){
     .icon-produit-dici{
      max-width: 250px;
      margin: 0 auto;
    }

    }
  
    @media screen and (max-width: 1025px){

      #container-produit-dici h2, #container-produit-dici h3{
        font-size: 1.5rem;
      }

      .icon-produit-dici{
        width: 10rem;
        margin: 0 auto;
      }
      }

  @media screen and (max-width: 991px){
         .rightpush {
          right: 0;
        }
        }
  
  @media screen and (max-width: 768px){
      .prod-qc-max-lg{
        max-width: 10rem;
       }

       .rightpush {
        right: 0;
      }
      }
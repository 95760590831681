
/*
    Page header
*/

.page__header {
	/*height: 370px;hauteur variable en cas de format différent en mobile*/
	/*height: 150px;  hauteur habituelle*/
	min-height: 375px;
	padding-bottom: 40px;
	position: relative;

	@include media-breakpoint-up(md) {
		height: 13rem;
		min-height: 411px;
	}

	@include media-breakpoint-up(xl) {
		height: 34rem;
		min-height: 300px;
	}
}


@include media-breakpoint-up(md) {

	.page__header {
		padding-bottom: 0; ;
		/* format desktop en cas de format header différent en mobile 
		background-image:url('/dist/img/20240704-hero-D.jpg')!important;*/
		background-position: center center!important;
		background-size: cover!important;
		
	}

}



/*
    Pge header heading block
*/

.page__header__heading-block {
	background: rgba( 255, 255, 255, 0.9 );
	box-shadow: 0 0 0 14px rgba( 209, 224, 229, 0.9 );
	left: 50%;
	margin: 0 auto;
	max-width: 500px;
	padding: 40px;
	position: absolute;
	text-align: center;
	top: 80px;
	transform: translateX( -50% );
	width: 90%;
}

.page__header__heading-block__title {
	/*font-family: $f-butler-bold;*/
	font-family: $f-mplusrounded-extra-bold;
	font-size: 2.5rem;
	margin-bottom: 1rem;
}

.current-store{
	font-size: 16px;
	text-transform: uppercase;
	color: #DF271C;
	font-family: $f-mplusrounded-extra-bold;
}

#titre-prod-dici{
	font-size: 3rem;
	color: #ffffff;
	@include media-breakpoint-down(lg) {
		font-size: 2rem;
		text-align: center;
	}
}


